import Lightbox from '@/Components/Common/Lightbox.jsx';
import {Fieldset, FieldsetImage} from '@/Pages/Configurator/Styles/Fieldset.jsx';
import ImagesStyle from '@/Styles/images.js';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import {Box, Button, Link as MuiLink, Stack, Typography} from '@mui/material';
import React, {memo} from 'react';

const YaKOutro = ({amount_max_monthly, openBudgetDetails}) => {
    let decor = {image: '/theme/ya-k/images/pages/configurator/decors/photos-outro.png', width: 1043, height: 846};

    return (
        <Fieldset>
            <Box>
                <Typography variant="fancy_h5" sx={{mb: 1.5}}>
                    <Box
                        component="span"
                        sx={(theme) => ({
                            color: theme.palette.primary.main,
                        })}
                    >
                        Votre
                    </Box>{' '}
                    chez vous
                </Typography>
            </Box>

            <Lightbox id={'gallery-budget'}>
                <MuiLink
                    href={decor.image}
                    data-pswp-width={decor.width}
                    data-pswp-height={decor.height}
                    target="_blank"
                    rel="noreferrer"
                >
                    <FieldsetImage src={decor.image} alt="" />
                </MuiLink>
            </Lightbox>

            <Box>
                <Typography variant="fancy_subtitle1" component="p" sx={{mb: {xs: 2, md: 4}}}>
                    Budget estimé du projet
                </Typography>
                <Stack
                    direction="row"
                    sx={{flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', mb: 10}}
                >
                    <Typography variant="h4" component="span" sx={{fontWeight: 600}}>
                        À partir de <br /> {amount_max_monthly}&nbsp;€ /&nbsp;mois *
                    </Typography>
                    <MuiLink
                        variant="body1"
                        underline="hover"
                        onClick={openBudgetDetails}
                        sx={{fontStyle: 'italic', color: 'inherit'}}
                    >
                        Voir le détail
                    </MuiLink>
                </Stack>
                <Box sx={{display: 'flex', flexFlow: 'column nowrap'}}>
                    <Button
                        variant="outlined"
                        color="text"
                        href=""
                        size="large"
                        fullWidth
                        startIcon={<SaveAltOutlinedIcon size="small" />}
                        sx={{mx: 'auto', mb: 1.5, maxWidth: 300}}
                    >
                        Télécharger le récapitulatif PDF
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        fullWidth
                        sx={(theme) => ({
                            position: 'relative',
                            mx: 'auto',
                            maxWidth: 300,
                            '&::after': {
                                ...ImagesStyle.background_contain('/theme/ya-k/images/icons/lignes-jaunes.svg'),
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                transform: 'scaleX(-1) translate(-100%, 100%)',
                                width: '35px',
                                height: '43px',
                                [theme.breakpoints.up('md')]: {
                                    top: -10,
                                    right: -2,
                                    width: '52px',
                                    height: '64px',
                                },
                            },
                        })}
                    >
                        Finaliser mon projet
                    </Button>
                </Box>
            </Box>
        </Fieldset>
    );
};

export default memo(YaKOutro);
